const PRESENCE_POSTE_TO_PDF = 'presencePostes/exportToPdf'
const POINT_PRESENCE_TO_PDF = 'pointPresences/exportToPdf'
const PRISE_SERVICE_TO_PDF = 'priseServices/exportToPdf'
const ARRET_CONTRAT_TO_PDF = 'arretContrats/exportToPdf'
const DEMANDEUR_LIST_TO_PDF = 'demandeurs/exportToPdf'
const DEMANDEUR_MATCHING_TO_PDF = 'demandeurs/rechercherProfils/exportToPdf'
const PLACEMENT_LIST_TO_PDF = 'placements/exportToPdf'
const PARTICIPATION_PLACEMENT_LIST_TO_PDF = 'participationPlacements/exportToPdf'
const PRISE_EN_CHARGE_LIST_TO_PDF = 'priseEnCharges/exportToPdf'
const CANDIDATURE_LIST_TO_PDF = 'candidatures/exportToPdf'
const OFFRE_LIST_TO_PDF = 'offres/exportToPdf'
const ENTREPRISE_LIST_TO_PDF = 'entreprises/exportToPdf'
const OFFRE_EXTERNE_LIST_TO_PDF = 'offresExternes/exportToPdf'
const MICROPROJET_LIST_TO_PDF = 'microprojets/exportToPdf'
const ACTIVITE_ANPE_LIST_TO_PDF = 'activiteAnpes/exportToPdf'
const PARTICIPATION_ANPE_LIST_TO_PDF = 'participationActivites/exportToPdf'


export const exportToPdfSpecs = {
  [PRESENCE_POSTE_TO_PDF]: {
    title: 'LISTE DE PRESENCE AU POSTE',
    subtitle: '',
    headers: {
      'Nom Prénom': 'beneficiaireNom',
      'Prénom': 'beneficiairePrenom',
      'Phone': 'beneficiairePhone',
      'Poste':'participationPlacement.posteOccupe', 
      'Type Emploi': 'participationPlacement.typePlacement.libelle',
      'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
      'Nbre absences': 'nombreAbsenceSansMotif',
      'Date Démarrage':'dateDemarrage', 
    },
    metas: {
      'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
      // 'N° CONTRAT' : 'participationPlacement.placement.contract.numeroContrat',
      // 'DATE PLACEMENT' : '',
      'N° PLACEMENT' : 'participationPlacement.placement.reference',
      'MOIS POINT DE PRESENCE' : 'pointPresence.moisAnnee',
      'PERIODE DU' : 'pointPresence.dateDu',
      'PERIODE AU' : 'pointPresence.dateAu',
    },
  },

  [POINT_PRESENCE_TO_PDF]: {
    title: 'LISTE DE POINT DE PRESENCE',
    subtitle: '',
    headers: {
      'Ref: Placement': 'placement.reference',
      'Li': 'placement.intituleOffre',
      'Mois':'dateMoisPointPresence', 
      'Début Période': 'dateDu',
      'Fin Période': 'dateAu',
      'Statut': 'status',
    },
    metas: {
      'ENTREPRISE' : 'placement.entreprise.raisonSocial',
      'N° PLACEMENT' : 'placement.reference',
      'INTITULE PLACEMENT' : 'placement.intituleOffre',
    },
  },
  
  [PRISE_SERVICE_TO_PDF]: {
    title: 'LISTE DE PRISE DE SERVICE',
    subtitle: '',
    headers: {
      'Ref: Placement': 'participationPlacement.placement.reference',
      'Li': 'participationPlacement.placement.intituleOffre',
      'Nom': 'beneficiaireNom',
      'Prénom': 'beneficiairePrenom',
      'Provenance': 'communeProvenance.nom',
      'Date P.S.':'datePriseService', 
      'Observation': 'observation',
    },
    metas: {
      'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
      'N° PLACEMENT' : 'participationPlacement.placement.reference',
      'INTITULE PLACEMENT' : 'participationPlacement.placement.intituleOffre',
    },
  },

  [ARRET_CONTRAT_TO_PDF]: {
    title: 'LISTE DE PRESENCE AU POSTE',
    subtitle: '',
    headers: {
      'Ref: Placement': 'participationPlacement.placement.reference',
      'Placement': 'participationPlacement.placement.intituleOffre',
      'Entreprise': 'participationPlacement.placement.entreprise.raisonSocial',
      'Date Arrêt': 'dateArret',
      'Raison': 'typeArret.libelle',
      'Observation': 'observation',
    },
    metas: {
      'ENTREPRISE' : 'participationPlacement.placement.entreprise.raisonSocial',
      'N° PLACEMENT' : 'participationPlacement.placement.reference',
      'INTITULE PLACEMENT' : 'participationPlacement.placement.intituleOffre',
    },
  },

  [DEMANDEUR_LIST_TO_PDF]: {
    title: 'LISTE DES DEMANDEURS D\'EMPLOI',
    subtitle: 'LISTE DES DEMANDEURS D\'EMPLOI',
    headers: {
      'Nom': 'nom',
      'Prénom': 'prenom',
      'Sexe': 'sexe',
      'Département': 'departement.nom',
      'Commune': 'commune.nom',
      'phone': 'phone',
      'NPI': 'npi',
    },
    meta: {}
  },
  [DEMANDEUR_MATCHING_TO_PDF]: {
    title: 'LISTE DES DEMANDEURS D\'EMPLOI MATCHE',
    subtitle: 'LISTE DES DEMANDEURS D\'EMPLOI MATCHE',
    headers: {
      'Nom': 'nom',
      'Prénom': 'prenom',
      'Sexe': 'sexe',
      'Date Nais.': 'dateNais',
      'Département': 'departement.nom',
      'Commune': 'commune.nom',
      'Tél': 'phone',
      'NPI': 'npi',
      'NPI Validé': 'npiValide',
      'Catégorie': 'categorieDemandeur',
      'Statut Dossier': 'status',
      'Niveau PEC': 'niveauPriseEnCharge',
      'Niveau Instruction': 'niveauInstruction.code',
      'Nombre MER': 'nombreMer',
      'Nombre Placement': 'nombrePlacement',
      'Type Emploi': 'typeCheminEmploi',
      'Agent Nom': 'suiviPar.nom',
      'Agent Prénom': 'suiviPar.prenom',
      'Agent Prénom': 'suiviPar.prenom',
      'Agent Fonction': 'suiviPar.fonctionNom',
      'Agent Antenne': 'suiviPar.antenneNom',
    },
    meta: {}
  },

  [PLACEMENT_LIST_TO_PDF]: {
    title: 'LISTE DE POINT DE PRESENCE',
    subtitle: '',
    headers: {
      'Ref: Placement': 'reference',
      'Entreprise': 'entreprise.raisonSocial',
      'Dirigeant': 'entreprise.nomDirigeant',
      'IFU': 'entreprise.ifu',
      'Offre.Intitule': 'offre.intitule',
      'OffreRef': 'offre.reference',
      'MER': 'miseEnRelationCount',
      'PLACES': 'placesCount',
      'Date':'createdDate', 
      // 'Statut': 'statut',
    },
    metas: {
    },
  },

  [PARTICIPATION_PLACEMENT_LIST_TO_PDF]: {
    title: 'LISTE DES PLACEMENTS BENEFICIES',
    subtitle: '',
    headers: {
      'ID': 'beneficiaireId',
      'Bénéf Nom': 'beneficiaireNom',
      'Bénéf Prénom': 'beneficiairePrenom',
      'Bénéf Sexe': 'beneficiaireCivilite.sexe',
      'Tél': 'beneficiairePhone',
      'Dèpartment': 'beneficiaireDepartementNom',
      'Commune': 'beneficiaireCommuneNom',
      'Poste': 'posteOccupe',
      'Offre. ': 'placementOffre.intitule',
      'Entre. ': 'entreprise.raisonSocial',
      'Entre.Azoli ': 'entreprise.isCandidatAzoli',
      'Type': 'typePlacement.libelle',
      'Début.Cont':'dateDebut', 
      'Fin.Cont': 'dateFin',
      'Date.PS': 'datePriseService',
      'Statut': 'statutPlacement',
    },
    metas: {
      'ENTREPRISE' : 'placement.entreprise.raisonSocial',
      'N° PLACEMENT' : 'placement.reference',
      'INTITULE PLACEMENT' : 'placement.intituleOffre',
    },
  },

  [PRISE_EN_CHARGE_LIST_TO_PDF]: {
    title: 'LISTE DES PRISES EN CHARGE',
    subtitle: '',
    headers: {
      'Bénéf Nom': 'demandeur.nom',
      'Bénéf Prénom': 'demandeur.prenom',
      'Type demandeur': 'demandeur.estPPI',
      'Statut professionnel': 'statutProfessionnel.libelle',
      'Type.Emploi': 'cheminEmploi',
      'Degré':'classement.libelle', 
      'Embauché par': 'structureEmbauche',
    },
    metas: {},
  },

  [CANDIDATURE_LIST_TO_PDF]: {
    title: 'LISTE DES CANDIDATURES',
    subtitle: '',
    headers: {
      'Cand.Nom': 'candidat.nom',
      'Cand.Prénom': 'candidat.prenom',
      'Cand.Phone': 'candidat.phone',
      'Cand.NPI': 'candidat.npi',
      'Cand.ID': 'candidat.id',
      'Motivation': 'motivation',
      'Date': 'createdDate',
      'Evaluée':'isEvalue', 
      'Statut': 'status',
    },
    metas: {
      'ENTREPRISE': 'offre.employeur.raisonSocial',
      'IFU': 'offre.employeur.ifu',
      'Offre': 'offre.intitule',
      'Niveau Service': 'offre.intitule',
    },
  },

  [ENTREPRISE_LIST_TO_PDF]: {
    title: 'LISTE DES ENTREPRISES',
    subtitle: '',
    headers: {
      'Ref': 'reference',
      'Dénomination': 'raisonSocial',
      'Dirigeant': 'nomDirigeant',
      'IFU': 'ifu',
      'RCCM': 'rccm',
      'Tél': 'phone',
      'Département': 'departement.nom',
      'Commune': 'commune.nom',
    },
    metas: {
      // 'ENTREPRISE': 'employeur.raisonSocial',
      // 'IFU': 'employeur.ifu',
      // 'Offre': 'offre.intitule',
      // 'Niveau Service': 'offre.intitule',
    },
  },
  [OFFRE_LIST_TO_PDF]: {
    title: 'LISTE DES OFFRES',
    subtitle: '',
    headers: {
      'Ref': 'reference',
      'Intitulé': 'intitule',
      'Entreprise': 'employeur.raisonSocial',
      'Niveau Service': 'niveauService',
      'Age Min': 'ageMin',
      'Age Max':'ageMax', 
      'Nbre. Post': 'nombre',
      'Nbre Cand.': 'candidaturesCount',
      'Type Offre': 'typeOffre',
      'Durrée Exp.': 'annExp',
      // 'Date d\'éffet': 'dateEff',
      // 'Date d\'échéance': 'dateEch',
      // 'Statut': 'status',
    },
    metas: {
      // 'ENTREPRISE': 'employeur.raisonSocial',
      // 'IFU': 'employeur.ifu',
      // 'Offre': 'offre.intitule',
      // 'Niveau Service': 'offre.intitule',
    },
  },

  [OFFRE_EXTERNE_LIST_TO_PDF]: {
    title: 'LISTE DES OFFRES',
    subtitle: '',
    headers: {
      'Ref': 'reference',
      'Intitulé': 'intitule',
      'Entreprise': 'entreprise',
      'Nature': 'nature.code',
      'Age Min': 'ageMin',
      'Age Max':'ageMax', 
      'Nbre. Post': 'nombre',
      'Date clôture': 'dateCloture',
      'Statut': 'status',
    },
    metas: {},
  },

  [MICROPROJET_LIST_TO_PDF]: {
    title: 'LISTE DES MICROPROJETS',
    subtitle: '',
    headers: {
      'Titre microprojet': 'titre',
      'Nom': 'beneficiaire.nom',
      'Prénom': 'beneficiaire.prenom',
      'Tél': 'beneficiaire.phone',
      'Sexe': 'beneficiaire.sexe',

      'Département': 'departement.nom',
      'Commune':'commune.nom', 
      'Arrondissement':'arrondissement.nom', 
      'Quartier/Village':'ville.nom', 
      'Programme': 'programme.nom',
      'SFD': 'sfdFinancier.denomination',
      'Statut': 'status',
    
    //   'Nbre.Access': 'fnmAccessCount',
    },
    metas: {
    },
  },

  [PARTICIPATION_ANPE_LIST_TO_PDF]: {
    title: 'LISTE DES PARTICIPANTS POUR TÂCHE PLANIFIEE',
    subtitle: '',
    headers: {
      'Ref': 'reference',
      'Microprojet': 'titre',
      'Nom': 'beneficiaire.nom',
      'Prénom': 'beneficiaire.prenom',
      'Sexe': 'beneficiaire.sexe',
      'Département': 'departement.nom',
      'Commune':'commune.nom', 
      'Programme': 'programme.nom',
      'Nbre.Access': 'fnmAccessCount',
    },
    metas: {
      'Titre': 'titre',
      'Type Activité': 'typeActivite.libelle',
      'Date Prévue': 'datePrevue',
      'Statut': 'statusActivite',
    },
  },
  [ACTIVITE_ANPE_LIST_TO_PDF]: {
    title: 'LISTE DES TÂCHES PLANIFIEES',
    subtitle: '',
    headers: {
      'Ref': 'reference',
      'Microprojet': 'titre',
      'Nom': 'beneficiaire.nom',
      'Prénom': 'beneficiaire.prenom',
      'Sexe': 'beneficiaire.sexe',
      'Département': 'departement.nom',
      'Commune':'commune.nom', 
      'Programme': 'programme.nom',
      'Nbre.Access': 'fnmAccessCount',
    },
    metas: {
      'Titre': 'titre',
      'Type Activité': 'typeActivite.libelle',
      'Date Prévue': 'datePrevue',
      'Statut': 'statusActivite',
    },
  },
}

let excelSpecs = { }
Object.keys(exportToPdfSpecs).forEach(path => excelSpecs[path.replace('ToPdf', 'ToExcel')] = exportToPdfSpecs[path])
export const exportToExcelSpecs = {
  ...excelSpecs
}