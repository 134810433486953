// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from "../../utils/pagination-util";

export const actions = {
    fetchPrescriptions({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination);
    const { extraQueryArg = "" } = params;
    return get(`/prescriptions?${queryParams}&${extraQueryArg}`)
    .then(({ data }) =>{
      commit('addPrescriptions', data)
    })
  },
  createPrescription({ commit }, prescription){
    return post('/prescriptions', prescription)
    .then(({ data }) => {
      commit('addPrescription', data)
    })
  },
  updatePrescription({ commit }, prescription){
    console.log('......................', prescription)
    return new Promise((resolve, reject) => {
      put(`/prescriptions/${prescription.id}`, prescription)
      .then(({ data }) =>{
        commit('replacePrescription', data)
        resolve(data)

      }).catch(err => reject(err))
    })
  },
  deletePrescription({ commit }, prescription){
    return remove(`/prescriptions/${prescription.id}`, prescription)
    .then(() =>{
      commit('removePrescription', prescription)
    })
  },
  createOrUpdatePrescription({ dispatch }, prescription){
    if(prescription.id !== undefined){
      return dispatch('updatePrescription', prescription)
    }
    else {
      return dispatch('createPrescription', prescription)
    }
  },
  deleteContenuPrescription({ commit }, contenu){
    return remove(`/contenuPrescriptions/${contenu.id}`, contenu)
    .then(() =>{
      commit('removeContenuPrescription', contenu)
    })
  },
  createContenuPrescription({ commit }, contenu){
    if(contenu.prescription == null) throw Error(`'prescription' de contenuPrescription non specifié 'store.prescription.createContenuPrescription'`)
    return new Promise((resolv, reject) => {
      return post(`/contenuPrescriptions`, contenu)
      .then(({ data }) =>{
        commit('addContenuPrescription', data)
        resolv(data)
      }).catch(err => reject(err))
    })
  },
  updateContenuPrescription({ commit }, contenu){
    if(contenu.prescription == null) throw Error(`'prescription'  de contenuPrescription non specifié 'store.prescription.updateContenuPrescription'`)
    return new Promise((resolv, reject) => {
      return put(`/contenuPrescriptions/${contenu.id}`, contenu)
      .then(({ data }) =>{
        commit('replaceContenuPrescription', data)
        resolv(data)
      }).catch(err => reject(err))
    })
  },
  updateOrCreateContenuPrescription({ dispatch }, contenu){
    if(contenu.id !== undefined){
      return dispatch('updateContenuPrescription', contenu)
    }
    else {
      return dispatch('createContenuPrescription', contenu)
    }
  },
  selectPrescription({ commit }, prescription){
    commit('setPrescription', prescription)
  }
}