<template>
  <PDialog
    :header="title"
    :modal="true"
    :visible.sync="display"
    :containerStyle="{ width: '40vw' }"
  >
    <template #header>
      <h3>Exportation de fichier</h3>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="card mt border-0">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <template>
            <div class="row grid-md">
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      v-uppercase
                      id="title"
                      class="form-control"
                      name="title"
                      v-model="editableItem.title"
                    />
                    <label for="title">Titre de l'état.</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                  <!-- <small class="text-help"></small> -->
                </ValidationProvider>
              </div>
              <div class="col-sm-12 field">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      v-uppercase
                      id="subtitle"
                      class="form-control"
                      name="subtitle"
                      v-model="editableItem.subtitle"
                    />
                    <label for="subtitle">Description du contenu du fichier</label>
                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </div>
                  <!-- <small class="text-help"></small> -->
                </ValidationProvider>

                <div
                  class="col-sm-12 field"
                  v-if="Object.keys(editableItem.headers).length != 0"
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="title-box">
                      <h5 class="pb-0 mb-0">Données à exporter</h5>
                      <div class="mb-1">
                        <small class="text-muted">Cochez les colonnes à exporter</small>
                      </div>
                    </div>

                    <div class="d-flex action-box">
                      <PCheckbox
                        :id="`selectAll`"
                        :name="`selectAll`"
                        :binary="true"
                        v-model="selectAll"
                      />

                      <label :for="`selectAll`" class="ms-2 w-100 cursor-pointer">
                        Cocher tous</label
                      >
                    </div>
                  </div>

                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div
                      class="field-checkbox border py-1 mb-1 ps-1"
                      v-for="(opt, val, i) in editableItem.headers"
                      :key="opt"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input form-check- ps-1"
                        :id="`selectedHeaders_${i}`"
                        :name="`selectedHeaders_${i}`"
                        :value="opt"
                        v-model="editableItem.selectedHeaders"
                      />
                      <label
                        :for="`selectedHeaders_${i}`"
                        class="ms-2 w-100 cursor-pointer"
                        >{{ val }}</label
                      >
                    </div>

                    <span
                      v-for="(err, index) in errors"
                      :key="index"
                      class="text-danger"
                      >{{ err }}</span
                    >
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </template>
        </form>
      </div>
    </ValidationObserver>

    <template #footer>
      <PButton
        label="Annuler"
        @click.prevent="close"
        icon="pi pi-times"
        class="p-button-text"
      />
      <PButton label="Valider" @click.prevent="submit" icon="pi pi-check" autofocus />
    </template>
  </PDialog>
</template>

<script>
import { XDialog } from "./plugin";

export default {
  data() {
    return {
      title: null,
      editableItem: {},
      display: false,
      selectAll: false,
      params: {
        type: "PDF",
        title: null,
        subtitle: null,
      },
      onCancel: null,
      onAccept: null,
    };
  },
  beforeMount() {
    XDialog.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  computed: {
    isAllSelected() {
      return (
        this.editableItem.selectedHeaders.length ===
        Object.keys(this.editableItem.headers).length
      );
    },
  },
  watch: {
    selectAll(val) {
      if (val) {
        this.editableItem.selectedHeaders = Object.values(this.editableItem.headers);
      } else {
        if (this.isAllSelected) this.editableItem.selectedHeaders = [];
      }
    },
    "editableItem.selectedHeaders": {
      handler(newVal) {
        if (newVal && this.editableItem.headers) {
          this.selectAll =
            newVal.length === Object.values(this.editableItem.headers).length;
        }
      },
      deep: true,
    },
  },
  methods: {
    selectAllToggled(ev) {
      alert(ev);
    },
    resetContent() {
      this.params = {
        type: "PDF",
        title: null,
        subtitle: null,
      };
    },
    show(params) {
      let { onCancel, onAccept, meta } = params;
      let headers = meta.options && meta.options.headers ? meta.options.headers : {};
      this.editableItem = {
        title: meta.title,
        subtitle: meta.subtitle,
        headers: headers,
        selectedHeaders: [...Object.values(headers)],
      };

      this.onAccept = onAccept;
      this.onCancel = onCancel;

      this.resetContent();
      this.display = true;
    },
    submit() {
      if (this.onAccept != null) this.onAccept({ ...this.editableItem });
      if (this.onCancel != null) this.onCancel({ ...this.editableItem });
      this.hide();
    },
    close() {
      this.hide();
    },
    hide() {
      this.display = false;
    },
  },
};
</script>

<style scoped>
.form-check-input {
  transform: scale(1.4); /* Increase the size */
  margin-right: 10px; /* Add spacing if needed */
}
</style>
