import { downloadFileWithAxiosPost } from '@/api/api'
import { exportToPdfSpecs, exportToExcelSpecs } from './export-specs'

export const actions = {
  exportToFile({ },  params = {}){
    const { queryParams, apiPath, fileName = 'file', meta } = params
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToFile`)
    let querySpec = exportToPdfSpecs[apiPath]
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
  exportToPdf({ dispatch },  params = {}){
    const { queryParams, apiPath, fileName = 'file.pdf', meta } = params
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToPdf`)
    let querySpec = exportToPdfSpecs[apiPath]
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
  exportToExcel({ }, params = {  }){
      let { queryParams, apiPath, fileName, meta } = params
      const {selectedHeaders} = meta
    if(fileName == null) fileName= `${meta.title}.xlsx`
    if(apiPath == null) throw Error(`'apiPath' value is required. store/exporter/exportToExcel`)

    let querySpec = exportToExcelSpecs[apiPath]
    console.clear()
    console.log(querySpec)
    console.log(exportToExcelSpecs)
    let headers = {}
    for(let key in querySpec.headers){
        if(selectedHeaders.includes(querySpec.headers[key])){
            headers[key] = querySpec.headers[key]
        }
    }
    return downloadFileWithAxiosPost(`${apiPath}?${queryParams}`, fileName, {
      ...querySpec,
      headers,
      ...meta,
    })
    .then(({ data }) =>{
      return data
    })
  },
}