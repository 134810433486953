import { get } from '@/api/api'

function formatStatistion(stats) {

  if(stats.length == 1 && !(stats[0] instanceof Array)) return stats

  const count = { }
  let total = (stats.length > 0 && stats[0].length > 2)? {key: 'TOTAL', total: 0 } : 0

  stats.forEach(el => {
    if(el.length == 2){
      count[el[0]]=el[1]
      total += el[1]
    }
    else {
      let key = el[0]
      count[key] = { ...count[el[0]] || {}, key, [key]: key, [el[1]]: el[2], total: count[key]? (count[key]['total'] || 0) + el[2] : el[2] }
      total['total'] += el[2]
      total[[el[1]]] = total[[el[1]]] != undefined ? total[[el[1]]] + el[2] : el[2]
    }
  })
  count['TOTAL'] = total
  return count
}

export const actions = {
  fetchStampStatistics({ commit }, params = {
    lines, columns
  }){
    let { structureId } = params
    let url = '/statistiques/timbre/structure'
    if(structureId !== undefined)
      url = url + '/' + structureId;
    return get(url)
    .then(({ data }) =>{
      commit('setStampStatistics', data)
    })
  },
  fetchDeCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    console.log(dateDeb)
    // dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    // dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)  
    // let url = `/stats/deCount/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    let url = `/stats/deCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      // commit('setDeCount', data)
      return data
    })
  },
  fetchGlobalStatistics({ commit }, { lines, columns, pivots, statisticType, dateDeb, dateFin, extraQueryArg }){
    let url = `/stats/statistics?${extraQueryArg}&lines=${lines.join(',')}&columns=${columns.join(',')}&pivot=${pivots != null && pivots.length != 0? pivots[0] : ''}&statisticType=${statisticType}`
    if(dateDeb != null){
      url +=`&dateDeb=${dateDeb}`
    }
    if(dateFin != null){
      url +=`&dateFin=${dateFin}`
    }
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchAzoliGlobalStatistics({ commit }, params = {}){
    let { dateDeb, dateFin } = params
    
    dateDeb = (dateDeb || new Date('2021-12-31').toISOString().substring(0, 10))
    dateFin = (dateFin || new Date('2080-12-31').toISOString().substring(0, 10))

    let url = `/stats/azoli/globalStatistics?dateDeb=${dateDeb}&dateFin=${dateFin}`
    return get(url)
      .then(({ data }) =>{
        return data
      }).catch(err => err)
  },
  fetchAzoliEnquetesStatistics({ commit }, params = {}){
    let { dateDeb, dateFin } = params
    
    dateDeb = (dateDeb || new Date('2021-12-31').toISOString().substring(0, 10))
    dateFin = (dateFin || new Date('2080-12-31').toISOString().substring(0, 10))

    let url = `/stats/azoli/globalEnquetesStatistics?dateDeb=${dateDeb}&dateFin=${dateFin}`
    return get(url)
      .then(({ data }) =>{
        return data
      }).catch(err => err)
  },
  fetchOneOffreStatistics({ commit }, { offreId }){
    let url = `/offres/${offreId}/dataCount`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchOffresStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/offreCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchFichePosteProgrammeStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/fichePosteProgrammeCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchPriseEnchagesStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    console.log(dateDeb)
    dateDeb = (dateDeb || new Date('2021-12-31').toISOString().substring(0, 10))
    dateFin = (dateFin || new Date('2080-12-31').toISOString().substring(0, 10))  
    let url = `/stats/priseEnChargeCount/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchPresencePosteCountStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    console.log(dateDeb)
    dateDeb = (dateDeb || new Date('2021-12-31').toISOString().substring(0, 10))
    dateFin = (dateFin || new Date('2080-12-31').toISOString().substring(0, 10))  
    let url = `/stats/presencePosteCount/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchMicroprojetStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    let url = `/stats/microProjets/statistics/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchMicroprojetStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    let url = `/stats/microProjets/statistics/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchPayementPerIndemniteCountStatistics({ commit }, { extraQueryArg }){
    let url = `/stats/payementPerIndemniteCount?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchPayementPerLiquidationCountStatistics({ commit }, { liquidationId, extraQueryArg }){
    if(liquidationId == undefined) throw new Error("'liquidationId' is required in statistic/fetchPayementPerLiquidationCountStatistics")
    let url = `/stats/liquidations/${liquidationId}/payementPerLiquidationCount?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchMicroprojetPerAgentStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)  
    let url = `/stats/microProjets/perAgent/statistics/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchEntreprisesStatistics({ commit }, { dateDeb, dateFin, extraQueryArg }){
    dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)  
    let url = `/stats/entrepriseCount/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchEntrepriseSeftStatistics({}){
    let url = `/stats/entreprise/me`
    return get(url)
    .then(({ data }) => {
      return data
    })
  },
  fetchActiviteCount({ commit },  { dateDeb, dateFin, extraQueryArg }){
    console.log(dateDeb)
    dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)  
    let url = `/stats/activiteCount/?createdDate=${dateDeb}&createdDate=${dateFin}&${extraQueryArg}`
    return new Promise((resolv, reject) => {
      return get(url)
      .then(({ data }) => {
        commit('setActiviteCount', data)
        resolv(data)
      }).catch(err => reject(err))
    })
  },
  fetchCandidatureCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`
    let url = `/stats/candidatureCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    }).catch(err => reject(err))
  },
  fetchPlacementCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/placementCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    }).catch(err => reject(err))
  },
  fetchConsigneCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/consigneCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    }).catch(err => reject(err))
  },
  fetchPrescriptionCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/prescriptionCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    }).catch(err => reject(err))
  },
  fetchPointPresenceCount({ commit }, { dateDeb, dateFin, extraQueryArg }){
    if(dateDeb !=  null) extraQueryArg += `&createdDate=${dateDeb}`
    if(dateFin !=  null) extraQueryArg += `&createdDate=${dateFin}`  
    let url = `/stats/pointPresenceCount/?${extraQueryArg}`
    return get(url)
    .then(({ data }) => {
      return data
    }).catch(err => reject(err))
  },
  // eslint-disable-next-line no-unused-vars
  fetchAzoliStatistics({ commit }, params = { criteria: 'ELIGIBLE', regroupements: 'SEXE', formatResult: true }){
    let { criteria, regroupements, formatResult = true, dateDeb, dateFin } = params

    dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)
    
    let regroupementParam = ''
    regroupements.split(',').forEach(r => {
      regroupementParam += 'regroupements=' + r + '&'
    })

    let url = `/stats/azoli/statistics?criteria=${criteria}&${regroupementParam}&dateDeb=${dateDeb}&dateFin=${dateFin}`
    return new Promise((resolv, reject) => {
      return get(url)
      .then(({ data }) =>{
        resolv(formatResult? formatStatistion(data) : data)
      }).catch(err => reject(err))
    })
  },
  fetchAffectationDEStatistics({ commit }, params){    
    // dateDeb = (dateDeb || new Date('2021-12-31')).toISOString().substring(0, 10)
    // dateFin = (dateFin || new Date('2080-12-31')).toISOString().substring(0, 10)


    let url = `/stats/agent/demandeurAffectationStatistics?criteria=${'TOUT'}&regroupements=${'CLASSEMENT'}`
    return get(url)
    .then(({ data }) =>{
      return data
    }).catch(err => reject(err))
  },
  selectStructure ({ commit }, structure) {
    commit('setActiveStructure', structure)
  }
}