// import axios from 'axios'
import { post, put, get, downloadFile, downloadFileWithAxiosPost } from '@/api/api'
import ShortUniqueId from 'short-unique-id';
// import { FileService, } from '../../api/file-service';
import { API_URL } from '../../config/api-config'
import { buildQueryParams } from '../../utils/pagination-util'
const uid = new ShortUniqueId({ length: 10 });


export const actions = {
  fetchDemandeurs({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/demandeurs?${queryParams}`)
    .then(({ data }) => {
      commit('addDemandeurs', data)
      return data
    }).catch(err => console.error(err))

  },
  fetchDemandeurDoublons({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/demandeurs/doublons?${queryParams}`)
    .then(({ data }) => {
      commit('addDemandeurs', data)
      return data
    }).catch(err => console.error(err))

  },
  fetchRechercherProfils({ commit, state, rootState }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/demandeurs/rechercherProfils?${queryParams}`)
    .then(({ data }) => {
      commit('addDemandeurs', data)
      return data
    }).catch(err => console.error(err))
  },
  apposerSignatureDossier({commit, state}, params = {}) {
    return put(`/demandeurs/${params.contratId}/apposerSignature?`, params)
      .then(({data}) => {
          commit('setDemandeur', data)
          commit('replaceDemandeur', data)
          return data;
      })
  },
  applyDoublonVerdict({commit, state}, params = {}) {
    return put(`/demandeurs/appliquerVerdictDoublon`, params)
      .then(({data}) => {
          commit('setDemandeur', data)
          commit('replaceDemandeur', data)
          return data;
      })
  },
  fetchMonDossier({ commit }){
    return get(`/demandeurs/moi`)
    .then(({ data }) =>{
      commit('setDemandeur', data)
      return data
    }).catch(err => console.error(err))
  },
  fetchCardreDossier({ commit }, params){
    let { demandeurId } = params
    return get(`/demandeurs/${demandeurId}/cardreDossiers`)
    .then(({ data }) =>{
      commit('setDemandeur', data)
      return data
    }).catch(err => console.error(err))
  },
  fetchDossier({ commit }, params){
    let { demandeurId } = params
    return get(`/demandeurs/${demandeurId}?`)
    .then(({ data }) =>{
      commit('setDemandeur', data)
      return data
    }).catch(err => console.error(err))
  },
  fetchPriseEnCharge({ commit }, params){
    let { demandeurId, priseEnChargeId } = params
    return get(priseEnChargeId == null?`/demandeurs/${demandeurId}/priseEnCharge` : `/priseEnCharges/${priseEnChargeId}`)
    .then(({ data }) =>{
      commit('setPriseEnCharge', data)
      return data
    }).catch(err => console.error(err))
  },
  exportToPdf({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/demandeurs/exportToPdf?${queryParams}`, 'Liste des bénéficiaires.pdf', {
      headers: {
        'Nom': 'nom',
        'Prénom': 'prenom',
        'Sexe': 'sexe',
        'Département': 'departement.nom',
        'Commune': 'commune.nom',
        'phone': 'phone',
      },
    })
    .then(({ data }) =>{
      commit('setPresencePoste', data)
      return data
    })
  },
  exportToExcel({ commit, state }, params = {  }){
    let queryParams = buildQueryParams(params, state.pagination)
    return downloadFileWithAxiosPost(`/demandeurs/exportToExcel?${queryParams}`, 'file.xlsx', {
      headers: {
        'Nom': 'nom',
        'Prénom': 'prenom',
        'Sexe': 'sexe',
        'Département': 'departement.nom',
        'Commune': 'commune.nom',
        'phone': 'phone',
      },
    })
    .then(({ data }) =>{
      commit('setPresencePoste', data)
      return data
    })
  },
  genererPriseEnChargePrescritions({ commit }, params){
    let { demandeurId } = params
    return post(`/demandeurs/${demandeurId}/prescriptions/genererPrescriptions`)
    .then(({ data }) =>{
      commit('setPriseEnChargePrescriptions', data)
      return data
    }).catch(err => console.error(err))
  },
  genererPriseEnChargeConsignes({ commit }, params){
    let { demandeurId } = params
    return post(`/demandeurs/${demandeurId}/consignes/genererConsignes`)
    .then(({ data }) =>{
      commit('setPriseEnChargeConsignes', data)
      return data
    }).catch(err => console.error(err))
  },
  updateDossier({ dispatch }, dossier){
    return dispatch('updateDemandeur', dossier)
  },
  updatedPriseEnCharge({ commit }, priseEnCharge){
    const { id } = priseEnCharge
    return put(`/priseEnCharges/${id}/`, priseEnCharge)
    .then(({ data }) =>{
      commit('setPriseEnCharge', data)
      return data
    })
  },
  updatePriseEnChargeCheminEmploi({ commit }, priseEnCharge){
    const { id } = priseEnCharge
    return put(`/priseEnCharges/${id}/updateCheminEmploi`, priseEnCharge)
    .then(({ data }) =>{
      commit('setPriseEnCharge', data)
      return data
    })
  },
  validerDossier({ commit }, demandeur){
    const { azoli, id } = demandeur
    return put(`/demandeurs/${id}/dossiers/valider`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  signalerDossierDoublon({ commit }, demandeur){
    const { azoli, id } = demandeur
    return put(`/demandeurs/${id}/dossiers/signalerDoublon`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  createDemandeur({ commit }, demandeur){
    return post('/demandeurs', demandeur)
    .then(({ data }) =>{
      commit('addDemandeur', data)
      return data
    })
  },
  downloadAzoli(context, demandeur){
    return downloadFile(`${API_URL}/demandeurs/${demandeur.id}/pdf`, `fichier${demandeur.id}.pdf`)
    // return Promise((resolve) => {
    //   resolve()
    // })
  },
  registerDemandeur({ commit }, demandeur){
    // /oauth/register/demandeur
    return post('/demandeurs', {
      ...demandeur,
      password: uid(),
      courriel: demandeur.courriel
    })
    .then(({ data }) =>{
      commit('addDemandeur', data)
      return data
    })
  },
  updateDemandeur({ commit }, demandeur){
    const { azoli, id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/dossiers`, azoli)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  updateDemandeurInfo({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/cardreDossiers`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  updateDemandeurCv({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/cardreDossiers`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  validerNpiInfo({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/validerNpiInfo`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  validerNpiInfo({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/validerNpiInfo`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  validerNpiInfo({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/validerNpiInfo`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  validerNpiInfo({ commit }, demandeur){
    const { id } = demandeur
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${id}/validerNpiInfo`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  appliquerMutationDemandeur({ commit }, demandeur){
    const { demandeurId } = demandeur
    if(demandeurId == null) throw new Error(`'store.demandeur.appliquerMutationDemandeur' missing 'demandeurId' `)
    // azoli.maitriseLangues = []
    return put(`/demandeurs/${demandeurId}/mutationDemandeur`, demandeur)
    .then(({ data }) =>{
      commit('replaceDemandeur', data)
      return data
    })
  },
  telechargerDemandeurCv({}, params = {}){
    return downloadFile(`/demandeurs/${params.demandeur.id}/exportCvToPdf?templateName=${params.templateName.replace('/','')}`, `${params.demandeur.nom}_${params.demandeur.prenom}_CV.pdf`)
    .then(({ data }) =>{
      return data
    })
  },
  createOrUpdateDemandeur({ dispatch }, demandeur){
      demandeur = {
        ...demandeur,
        courriel: demandeur.courriel
      }
      return dispatch('registerDemandeur', demandeur)
    // if(demandeur.id !== undefined){
    //   return dispatch('updateDemandeur', demandeur)
    // }
    // else {
    //   return dispatch('createDemandeur', demandeur)
    // }
  },
  selectDemandeur({ commit }, demandeur){
    commit('setDemandeur', demandeur)
  }
}