// import axios from 'axios'
import { post, put, get, remove } from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'

export const actions = {
  fetchConsignes({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/consignes?${queryParams}`)
    .then(({ data }) =>{
      commit('addConsignes', data)
    })
  },
  createConsigne({ commit }, consigne){
    return post('/consignes', consigne)
    .then(({ data }) => {
      commit('addConsigne', data)
    })
  },
  updateConsigne({ commit }, consigne){
    return new Promise((resolve, reject) => {
      put(`/consignes/${consigne.id}`, consigne)
      .then(({ data }) =>{
        commit('replaceConsigne', data)
        resolve(data)

      }).catch(err => reject(err))
    })
  },
  deleteConsigne({ commit }, consigne){
    return remove(`/consignes/${consigne.id}`, consigne)
    .then(() =>{
      commit('removeConsigne', consigne)
    })
  },
  createOrUpdateConsigne({ dispatch }, consigne){
    if(consigne.id !== undefined){
      return dispatch('updateConsigne', consigne)
    }
    else {
      return dispatch('createConsigne', consigne)
    }
  },
  selectConsigne({ commit }, consigne){
    commit('setConsigne', consigne)
  }
}